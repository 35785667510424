import BaseTopNav from '@/components/base/baseTopNav';
import TradeList from './components/VipTradeList';

/**
 * Vip 页面
 */
export default () => {
  return (
    <div className="mx-auto">
      <BaseTopNav title={'VIP'} />
      <div className="p-4">
        <TradeList></TradeList>
      </div>
    </div>
  );
};
